import Vue from 'vue'
import VueRouter from 'vue-router'
import Account from '../views/Account.vue'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/audits',
        beforeEnter: authGuard,
    },
    {
        path: '/audits',
        name: 'Audits',
        beforeEnter: authGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Audits.vue')
    },
    {
        name: 'Findings',
        path: '/audits/:auditId',
        beforeEnter: authGuard,
        component: () =>
            import ('../views/Findings.vue')
    },
    {
        path: '/account',
        name: 'Account',
        beforeEnter: authGuard,
        component: Account
    },
    {
        path: '/feedback',
        name: 'Feedback',
        beforeEnter: authGuard,
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Feedback.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router