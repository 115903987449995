<template>
  <div>
    <h2>Account</h2>
    <div v-if="!$auth.loading">
      <h5>{{ $auth.user.name }}</h5>
      <p>
        Email: {{ $auth.user.email }} <br />
        Company: {{ $auth.user[`https://skyswarm.sky360.com.au/company`] }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Account",
};
</script>

<style>
.vs-button {
  margin: 0 0 0 0;
}
</style>
