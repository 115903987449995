import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store';
Vue.config.productionTip = false;

// Styling
import Vuesax from 'vuesax'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vuesax/dist/vuesax.css';
Vue.use(VueGoodTablePlugin);
Vue.use(Vuesax, {
    colors: {
        primary: 'rgb(0, 151, 220)',
        success: 'rgb(23, 201, 100)',
        danger: 'rgb(242, 19, 93)',
        warning: 'rgb(255, 130, 0)',
        dark: 'rgb(36, 33, 69)'
    }
});
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Filters
Vue.filter('formatDate', function(date) {
    if (date != undefined) {
        const day = date.substring(8);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${day}/${month}/${year}`;
    }
});

// Auth0
import { domain, clientId } from "../auth_config.json";
import { Auth0Plugin } from "./auth";
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl ?
            appState.targetUrl :
            window.location.pathname
        );
    }
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');